.item-row:not(:last-child) {
    border-bottom: 1px dotted gray;
}

.item-row {
    font-size: 12px;
    padding: 2px;
}
.item-download-btn {
    font-size: 12px;
    
    &:hover {
        transform: scale(1.1);
    }
}