.content {
    padding: 7rem 0;
}

.form-group {
    margin-bottom: 1rem;
}
.heading {
    font-size: 2.5rem;
    font-weight: 900;
}

.form-control {
    border: none;
    background: #d6d2d2;
}

.control-h-50 {
    height: 50px;
}

label.error {
    font-size: 12px;
    color: red;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.img-fluid{
    mix-blend-mode: multiply;
    filter: contrast(1);
}