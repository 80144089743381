.logo {
    width: 50px;
    height: auto;
}

.logotext{
    font-size: calc(10px + 1vw);
    font-weight: 800;
    font-family: 'Abril Fatface', cursive;
}

  