.App {
  margin-top: 70.7px;
  /* position: relative; */
}

.product-section {
  background-color: aliceblue;
}

.aboutus-description {
  color: #777777;
}

.partners-section{
  color: #777777;
}

.contactus-section{
  background: aliceblue
}